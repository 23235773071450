<template>
  <div id="checkinActive" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <v-card id="dialog" class="TF type2" width="100%">
        <div class="b-content">
          <div class="B-dialogform step">
            <div class="b-form" style="color:white; text-align: center; font-size: 30px;">
              <p>Thank you<br/>for the information</p>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import uploadBlob from "../utils/uploadBlob";

export default {
  data: () => ({
    checkbox1: false,
    options: {
      penColor: "#00f",
    },
    idPass: null,
    dialogSaveComplete: false,
    nuese_fullname:"",
  }),

  components: {},
  async mounted() {
    this.idPass = this.$route.params.id;
    this.userdata = await this.$CurrentUser.GetCurrentUser();
    this.nuese_fullname = this.userdata.u_firstname + " " + this.userdata.u_lastname;
  },
  methods: {
    async UndoSignature() {
      this.$refs.signaturePad.undoSignature();
    },
    async SaveSignatureToDB() {
      const dataURL = this.$refs.signaturePad.saveSignature();
      const resSignature = await uploadBlob(dataURL.data);

      try {
        let q1 = {};
        q1.p_id = this.idPass;
        let res1 = await feathersClientUOA
          .service("mubadala-health-form")
          .find({ query: q1 });
        if (res1.total == 1) {
          let mhf_id = res1.data[0].mhf_id;

          let dataSignatureForm1 = {
            mhf_pass_singnature: resSignature.data.Url,
            mhf_pass_sign_date: new Date(),
            mhf_nurse_name: this.nuese_fullname,
          };
          await feathersClientUOA
            .service("mubadala-health-form")
            .patch(mhf_id, dataSignatureForm1);
        }

        let q2 = {};
        q2.p_id = this.idPass;
        let res2 = await feathersClientUOA
          .service("mubadala-covid-form")
          .find({ query: q2 });
        if (res2.total == 1) {
          let mcf_id = res2.data[0].mcf_id;
          let dataSignatureForm2 = {
            mcf_pass_signature: resSignature.data.Url,
            mcf_pass_sign_date: new Date(),
            mcf_nurse_name: this.nuese_fullname,
          };
          await feathersClientUOA
            .service("mubadala-covid-form")
            .patch(mcf_id, dataSignatureForm2);
        }

        let q3 = {};
        q3.p_id = this.idPass;
        let res3 = await feathersClientUOA
          .service("mubadala-alcohol-form")
          .find({ query: q3 });
        if (res3.total == 1) {
          let maf_id = res3.data[0].maf_id;
          let dataSignatureForm3 = {
            maf_pass_signature: resSignature.data.Url,
            maf_pass_sign_date: new Date(),
            maf_nurse_name: this.nuese_fullname,
          };
          await feathersClientUOA
            .service("mubadala-alcohol-form")
            .patch(maf_id, dataSignatureForm3);
        }
        this.dialogSaveComplete = true;
      } catch (error) {
        console.log(error);
      }
    },

    ClosePage() {
      this.dialogSaveComplete = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
}

.t-company {
  font-size: 12px;
}
</style>>
